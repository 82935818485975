<template>
  <container-list-extend title="Acces" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="p-1 p-md-2 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="float-right text-success"
          fill="currentColor"
          v-if="acces.acces_active"
          v-tooltip="'Acces actif'"
        ></BaseIcon>
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="float-right text-danger"
          fill="currentColor"
          v-if="!acces.acces_active"
          v-tooltip="'Acces inactif'"
        ></BaseIcon>
        <h6 class="m-0 ml-3" style="line-height:1rem;">
          <description-from-list
            :value="acces.acces_site_client_id"
            :data="data.clientSites"
            text="Nouvel accès"
          ></description-from-list>
        </h6>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <div v-if="acces.acces_date_validite">
            validité :
            {{ $moment(acces.acces_date_validite).format("DD/MM/YYYY") }}
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form
        @click="updateAcces(acces)"
        deleteBtn
        @delete="deleteAcces(acces)"
        :loading="loading"
      >
        <base-form-row row>
          <base-select
            inputText="Type"
            v-model.number="acces.acces_type_id"
            :options="data.accesTypes"
          ></base-select>
          <base-select
            inputText="Site"
            v-model.number="acces.acces_site_client_id"
            :options="data.clientSites"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-inputDatePicker v-model="acces.acces_date_demande" inputText="Date de demande"></base-inputDatePicker>
          <base-inputDatePicker v-model="acces.acces_date_validite" inputText="Date de validité"></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-input-area v-model="acces.acces_commentaire" text="Commentaire"></base-input-area>
        </base-form-row>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import DescriptionFromList from "@/components/bases/DescriptionFromList";
import BaseIcon from "@/components/bases/Icon.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";

export default {
  name: "AccesItem",
  components: {
    BaseIcon,
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseSelect,
    BaseInputDatePicker,
    BaseInputArea,
    DescriptionFromList
  },
  data() {
    return {
      feedback: {},
      loading: false,
      tabView: "infos",
      statut: null
    };
  },
  props: ["acces"],
  computed: {
    ...mapGetters({
      data: "collaborateurs/data"
    })
  },
  methods: {
    ...mapActions({
      _updateAcces: "collaborateurs/updateAcces",
      _deleteAcces: "collaborateurs/deleteAcces"
    }),
    updateAcces: function(acces) {
      this.feedback = {};
      this.loading = true;
      this._updateAcces(acces)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteAcces: function(acces) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null
      this.feedback = {};
      this.loading = true;
      this._deleteAcces(acces)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="css">
</style>
