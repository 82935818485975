<template>
  <div class="d-flex flex-column flex-grow-1 rounded">
    <BaseToolBar title="Accès" subtitle="Liste des accès sur sites clients">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter un accès'"
        @click="storeAcces"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="acc in acces" :key="acc.id">
          <acces-item :acces="acc" v-if="acc.acces_active === false && acc.acces_date_validite === null"></acces-item>
        </div>
        <div v-if="acces.length > 0" class="mb-3 mt-3">
          <span
            @click="showActives = true"
            :class="{ 'text-muted': !showActives, 'text-primary': showActives }"
            class="mr-3 cursor-pointer"
            >acces actifs</span
          >
          <span
            @click="showActives = false"
            :class="{ 'text-muted': showActives, 'text-primary': !showActives }"
            class="mr-2 cursor-pointer"
            >acces inactifs</span
          >
        </div>
        <div v-if="showActives">
          <div v-for="acc in acces" :key="'actives_' + acc.id">
            <acces-item :acces="acc" v-if="acc.acces_active === true"></acces-item>
          </div>
        </div>
        <div v-if="!showActives">
          <div v-for="acc in acces" :key="'inactives_' + acc.id">
            <acces-item :acces="acc" v-if="acc.acces_active === false && acc.acces_date_validite !== null"></acces-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import BaseButton from "@/components/bases/Button.vue";
import AccesItem from "@/components/collaborateurs/profil/forms/acces/AccesItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";

export default {
  components: {
    AccesItem,
    BaseToolBar,
    BaseButton,
  },
  data() {
    return {
      newAcces: { acces_collaborateur_id: 0 },
      feedback: {},
      showActives: true,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      _storeAcces: "collaborateurs/createAcces",
    }),
    storeAcces: function () {
      this.feedback = {};
      this.loading = true;
      this._storeAcces(this.newAcces)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.newAcces.acces_collaborateur_id = this.id;
  },
  computed: {
    ...mapFields("collaborateurs", ["collaborateur.id"]),
    ...mapMultiRowFields("collaborateurs", ["acces"]),
    ...mapGetters({
      data: "collaborateurs/data",
    }),
  },
};
</script>
<style lang="css"></style>
